<template>
  <div class="form">
    <v-autocomplete
      v-model="customer"
      v-bind="basic"
      :items="customers"
      item-text="full_name"
      label="Select a Customer"
      :search-input.sync="search"
      return-object
      outlined
    ></v-autocomplete>
    <v-chip-group active-class="primary--text" column class="chips-box">
      <v-chip v-for="a in additionalInfos" :key="a.id">
        {{ a.field_label }}
      </v-chip>
    </v-chip-group>
    <v-btn depressed color="primary" v-on:click="submit" :loading="loading">
      Submit
    </v-btn>
  </div>
</template>
<script>
import util from "@/util/util";
export default {
  data() {
    return {
      basic: util.basic,
      form: {
        customer_id: null,
      },
      search: null,
      customers: [],
      customer: null,
      additionalInfos: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchCustomers();
    this.fetchBaseCustomer();
  },
  methods: {
    fetchCustomers(val = null) {
      let url = val == null ? "customer" : "customer?q=" + val;
      util
        .http({
          url: url,
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.customers = res.data.data.data;
          }
        });
    },
    fetchBaseCustomer() {
      util
        .http({
          url: "customer/base-customer",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            if (res.data.data) {
              this.customer = res.data.data;
              this.form.customer_id = res.data.data.id;
            }
          }
        });
    },
    fetchAdditionalInfos() {
      util
        .http({
          url: "/customer/" + this.form.customer_id + "/additional-fields",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.additionalInfos = res.data.data;
          }
        });
    },
    submit() {
      this.loading = true;
      util
        .http({
          url: "customer/set-base-customer",
          method: "put",
          data: this.form,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.notify(1, "Base Customer Set");
            util.event.$emit("reload-bulk-info");
          }
        });
    },
  },
  watch: {
    "form.customer_id": function() {
      this.fetchAdditionalInfos();
    },
    search(val) {
      if (this.customer == null) {
        this.fetchCustomers(val);
      } else {
        if (val && val === this.customer.full_name) {
          this.form.customer_id = this.customer.id;
        }
        val && val !== this.customer.full_name && this.fetchCustomers(val);
      }
    },
  },
};
</script>

<style scoped>
.form {
  padding-top: 0.4rem;
}
.chips-box {
  margin-top: -25px !important;
}
</style>
