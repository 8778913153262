<template>
  <div>
    <div class="pt-2" v-if="infos.length > 0" :key="key">
      <v-row>
        <v-col cols="6" v-for="(i, index) in infos" :key="i.index">
          <v-text-field
            class="mb-5"
            v-if="i.field_type == 'text'"
            v-bind="basic"
            color="primary"
            :label="form.items[index].field_label"
            required
            outlined
            v-model="form.items[index].field_value"
          ></v-text-field>

          <span v-if="i.field_type == 'file'" style="color: rgba(0,0,0,.6);">{{
            form.items[index].field_label
          }}</span>
          <fileUpload
            class="mb-5"
            v-bind="basic"
            v-if="i.field_type == 'file'"
            :index="index"
            @file-upload-success="fileUploadSuccess"
            @delete-file="deleteFile"
          />

          <v-textarea
            class="mb-5"
            v-bind="basic"
            v-if="i.field_type == 'textarea'"
            outlined
            name="input-7-4"
            :label="form.items[index].field_label"
            value=""
            v-model="form.items[index].field_value"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-btn depressed color="primary" v-on:click="submit()" :loading="loading">
        Submit
      </v-btn>
    </div>
    <div v-else class="text-center">
      Select a Base Customer
    </div>
  </div>
</template>
<script>
import util from "@/util/util";
import fileUpload from "./../UploadFiles.vue";
export default {
  components: { fileUpload },
  data: () => ({
    key: 0,
    basic: util.input.basic,
    infos: [],
    items: ["text", "file", "textarea"],
    selectedInputType: null,
    form: {
      items: [],
    },
    file: {},
    delete: true,
    loading: false,
  }),
  mounted() {
    this.fetchBaseInfos();
    util.event.$on("reload-bulk-info", () => {
      this.key++;
    });
  },
  methods: {
    fetchBaseInfos() {
      util
        .http({
          url: "customer/get-base-infos",
          method: "get",
        })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.infos = res.data.data;
            this.infos.map((info) => {
              this.form.items.push({
                field_label: info.field_label,
                field_type: info.field_type,
                field_value: "",
              });
            });
          }
        });
    },
    submit() {
      let data = {
        items: this.form.items,
        customer_id: this.$route.params.id,
      };
      this.loading = true;
      util
        .http({
          url: "customer/attach-additional-fields",
          method: "post",
          data: data,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.notify(1, "Additional Info Stored");
            this.delete = false;
            this.data = { items: {} };
            this.selectedInputType = "";
            this.form = { field_value: "", field_label: "", field_type: "" };
            util.event.$emit("refreshResource");
          }
        });
    },
    fileUploadSuccess(file, res, index) {
      this.form.items[index].field_value = res.data[0].url;
      this.delete = true;
    },
    deleteFile(file, index) {
      if (this.delete) {
        let url = this.form.items[index].field_value;
        util
          .http({
            url: "file-remove",
            method: "post",
            data: { url, name: file.name },
          })
          .then((res) => {
            if (res.data.status == 0) {
              util.notify(0, res.data.errors[0]);
            } else {
              delete this.form.items[index].field_value;
              util.notify(1, "Removed");
            }
          });
      }
    },
  },
};
</script>
