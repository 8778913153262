<template>
  <div>
    <Layout
      @filter="filter"
      :filterCriteriaProps="filterCriteria"
      :hasFilter="false"
      :hasPagination="false"
    >
      <template #menu>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-auto bg-btn" dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item style="cursor: pointer">
              <v-list-item-title v-ripple @click="onButtonPress()"
                >ADD</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <v-dialog v-model="popUpForm" max-width="800px">
        <additional-input />
      </v-dialog>
      <div class="mt-5">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="deep-purple accent-4"
        ></v-progress-linear>
        <v-simple-table class="event-table" dense>
          <template v-slot:default>
            <tbody class="table-body">
              <tr v-for="info in computedAdditionalInfos" :key="info.id">
                <th class="text-uppercase">{{ info.field_label }}</th>
                <td class="pl-5" v-if="info.field_type == 'file'">
                  <a :href="info.field_value" target="_blank">
                    <img :src="info.field_value" width="200" />
                  </a>
                </td>
                <td v-else>{{ info.field_value }}</td>
                <td>
                  <v-icon
                    small
                    title="Delete"
                    v-on:click="showDeleteModal(info.id)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </Layout>
    <v-dialog v-model="deleteModal" max-width="450px" v-if="id != 0">
      <deleteDialog
        resource="additional-fields"
        :id="id"
        @cancel="cancel"
        @delete-success="deleteSuccess"
      />
    </v-dialog>
  </div>
</template>

<script>
import util from "@/util/util";
import Layout from "@/components/Contact/PersonalContactsInfos/Layout.vue";
import AdditionalInput from "../PopupForms/AdditionalInput.vue";
import deleteDialog from "@/components/shared/delete-modal";
export default {
  components: { Layout, AdditionalInput, deleteDialog },
  data() {
    return {
      additionalInfos: [],
      filterCriteria: { q: "" },
      popUpForm: false,
      deleteModal: false,
      id: 0,
      loading: false,
    };
  },
  mounted() {
    this.fetchAdditionalInfos();
    util.event.$on("refreshResource", () => {
      this.fetchAdditionalInfos();
      this.popUpForm = false;
    });
  },
  methods: {
    filter(n) {
      this.filterCriteria = n;
    },
    onButtonPress() {
      this.popUpForm = true;
    },
    fetchAdditionalInfos() {
      this.loading = true;
      util
        .http({
          url: "/customer/" + this.$route.params.id + "/additional-fields",
          method: "get",
          params: this.filterCriteria,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.additionalInfos = res.data.data;
          }
        });
    },
    showDeleteModal(id) {
      this.deleteModal = true;
      this.id = id;
    },
    cancel() {
      this.deleteModal = false;
    },
    deleteSuccess() {
      this.deleteModal = false;
      util.notify(1, "Info deleted");
      this.fetchAdditionalInfos();
    },
  },
  computed: {
    computedAdditionalInfos: function() {
      if (this.searchTerm) {
        return this.additionalInfos.filter((info) => {
          return (
            info.field_label
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            info.field_value
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          );
        });
      } else {
        return this.additionalInfos;
      }
    },
  },
  watch: {
    filterCriteria: {
      handler() {
        this.fetchAdditionalInfos();
      },
      deep: true,
    },
    "$route.params.id": function() {
      this.fetchAdditionalInfos();
    },
  },
};
</script>

<style scoped>
.col-data {
  border: 1px solid #000;
}
.bg-btn {
  background: #1d2b58 !important;
  color: white !important;
}
/* .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
tr {
  display: table-row;
  border-color: inherit;
}
th {
  display: table-cell;
  border: 1px solid #000;
  font-weight: bold;
}
td {
  display: table-cell;
  border: 1px solid #000;
}
table {
  border-collapse: collapse !important;
  display: table;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey !important;
} */
</style>
